import { useParams } from "react-router-dom";
import CustomServices from "./../../services/services";
import { UserFetchUrl } from "./../../model/User.model";
import { useState } from "react";
import { Link } from "react-router-dom";
import Input from "./../../component/form/Input";
import style from "./stylepage/RecoveryPassword.module.css"
import Form from "./../../component/form/Form";

function RecoveryPassword() {

  let params = useParams();
  const data = useState<{newPass: string, replayPass: string}>({newPass:"", replayPass:""});
  const error = useState<{[key: string]: string}>({});

  const [action, setAction] = useState<"yes"|"no"|"wait">("wait");
  const [btn, setBtn] = useState<boolean>(true);

  async function sendChangePassword(e: React.FormEvent<HTMLFormElement>){
      e.preventDefault()
      setBtn(false);

      if(params.idmail){
        const id =  decodeURIComponent(params.idmail);
        
        if(data[0].newPass !== data[0].replayPass){
          error[1](prev => {return {...prev, "replayPass": "le password non coincidono"}})
          setBtn(true);

        }else{
          let response = await CustomServices.sendFetch<boolean>(UserFetchUrl.Changepassword(), {
            method: "POST",
            body: JSON.stringify({
              idMail: id,
              newPass: data[0].newPass
            })
          });

          setAction(res => response.data ? "yes" : "no");

          if(response.error){
            if(response.error.newPass) 
              response.error.newPass = "Inserisci:\nminimo 8 caratteri \nalmeno una lettera maiuscola e minuscola \nalmeno un numero e \nalmeno un simbolo";
            
            error[1](response.error);
            response.error.general && alert(response.error.general);
          }
        }
        

        
      }else{
        setAction("no");
        alert("email inesistente");
      }
  }

  return <>
    <div>
      <h1 className={style.title}>Recupero Password</h1>
      <Form onSubmit={sendChangePassword}>
        <Input type="password" name="newPass" label="nuova password" value={data} error={error}/>
        <Input type="password" name="replayPass" label="ripeti password" value={data} error={error}/>
        <div className={style["btn-box"]}>
          <button type="submit" className="btn">submit</button>
        </div>
      </Form>

      <div className={style["resalt-box"]}>
        {action === "yes" && <p>password cambiata , torna alla <Link to="../../../access">pagina di login</Link></p>}
        {action === "no" && <p>link scaduto</p>}
      </div>
     
    </div>
  </>
    
}

  export default RecoveryPassword;