import { RespFetch } from "./../model/Response.model";

export default class customValidateData{


    public isEmail(data) : RespFetch<boolean> {
        let response : RespFetch<boolean> = {};
        let reg = new RegExp(/^[^\.\s][\w\-\.{2,}]+@+(gmail|libero|hotmail|yahoo|live|icloud).+(com|it|io|org|net)$/,"g");
        reg.test(data)
            ? response.data = true 
            : response.error={email: "formato email non valido"}

        return response;
    }

    public isEmpity(data:string, field?: string): RespFetch<boolean> {
        let response : RespFetch<boolean> = {};
        
        data.trim() 
            ? response.data = true 
            : response.error={[field ?? "general"]: "il campo non può essere vuoto"}


        return response;
    }


} 