import customFetchServices from "./customFetch.service";
import { RespFetch } from "./../model/Response.model";
import customValidateData from "./customValidateData";
import cookieMenager from "./cookieMenager";

export default class CustomServices{
    constructor(_validateDataSingleton, _cookieMenager){
        _validateDataSingleton = new customValidateData();
        _cookieMenager = new cookieMenager();
    }
    static _validateDataSingleton: customValidateData = new customValidateData();
    static _cookieMenager: cookieMenager = new cookieMenager();

    static async sendFetch<res>(path: string , option: RequestInit) : Promise<RespFetch<res>>{
        return await customFetchServices<res>(path , option)
    }

    static validateData() : customValidateData{
        return this._validateDataSingleton;
    }

    static cookieMenager() : cookieMenager{
        return this._cookieMenager;
    }
}