import { FC, useContext, useState } from "react";
import style from "./customIFrame.module.css";

const CustomIFrame: FC<{src:string}> = ({src}) => {

    let c: any = {
        allowFullScreen:true,
        src,
        allow:"autoplay; fullscreen *; geolocation; microphone; camera; midi; monetization; xr-spatial-tracking; gamepad; gyroscope; accelerometer; xr; keyboard-map *; focus-without-user-activation *; screen-wake-lock; clipboard-read; clipboard-write",
        sandbox:"allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-presentation allow-scripts allow-same-origin allow-popups-to-escape-sandbox allow-downloads",
        allowTransparency:true,
        mozAllowAullScreen:true,
        msAllowFullScreen:true,
        webkitAllowFullScreen:true,
        scrolling:"no",
        frameBorder:"0",
        id:"game:drop",
    }

    return <>
            <iframe {...c} className={style.iframe}></iframe>
    </>
}

export default CustomIFrame;

