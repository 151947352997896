import { FC, useContext } from "react";
import { LoginContext } from "./../../store/context";
import CustomServices from "./../../services/services";
import style from "./Footer.module.css";
import { Link } from "react-router-dom";

const Footer : FC = () => {

    const [user, setUser] = useContext(LoginContext);

    async function Logout(e: React.MouseEvent<HTMLButtonElement, MouseEvent>){
        e.preventDefault();

        await CustomServices.sendFetch("auth/logout", {
            method: "GET",
          });

        document.location.reload();
    }

    return <footer className={style.footer}>
        <div>
            <p className={style.title}>Esplora</p>
            <ul className={style.list}>
                <li><Link to="">Home</Link></li>
                <li><Link to="events">Eventi</Link></li>
            </ul>
        </div>
        <div>
            <p className={style.title}>Seguici</p>
            <nav className={style.nav_social}>
                <Link className={`${style.link_logo} ${style.logo_instagram}`} to="https://www.instagram.com/adverplay/" target="_blank" title="Instagram">instagram</Link>
                <Link className={`${style.link_logo} ${style.logo_tiktok}`} to="https://www.tiktok.com/@adver_play" target="_blank" title="TikTok">tiktok</Link>
            </nav>
            
            <ul className={style.list}>
                
            </ul>
        </div>
    </footer>
}

export default Footer;