import { useParams } from "react-router-dom";
import CustomServices from "../../services/services";
import { useState } from "react";
import { Link } from "react-router-dom";
import style from "./stylepage/ConfirmMail.module.css";
import Form from "./../../component/form/Form";
import Input from "./../../component/form/Input";
import { ChangeEmailDto, UserFetchUrl } from "../../model/User.model";


function ChangeEmail() {

  let params = useParams();
  const password = useState<string>("");
  const error = useState<{[name: string]: string}>({});
  const confirmed = useState<boolean>(false)

  async function sendChangeEmail(e: React.FormEvent<HTMLFormElement>){
      e.preventDefault() 
      const id =  decodeURIComponent(params.idmail);
      let changeEmailDto : ChangeEmailDto = {
          password: password[0],
          idMail: id
      };

      let response = await CustomServices.sendFetch(UserFetchUrl.PostChangeEmail(), {
        method: "POST",
        body: JSON.stringify(changeEmailDto)
      });

      if(response.error){
        error[1](response.error);
      }else{
        confirmed[1](true);
      }

    
  }


  return <>
    <div className={style.box} >
      <h1 className={style.title}>Conferma email</h1>
      <p>Per confermare la tua nuova email, inserisci la password del tuo account</p>
      {confirmed[0] ? 
        <p className={style.alert}>Email confermata ora puoi accedere con la nuova email, <Link to="../../../access">pagina di login</Link></p>
    
      : <Form onSubmit={sendChangeEmail}>
      <Input type="password" name="password" value={password} error={error}/>
      <button type="submit">Invia</button>
    </Form>}
      
    </div>
  </>
    
}
export default ChangeEmail;