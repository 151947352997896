import { Outlet, useOutlet } from "react-router-dom";
import Popup from "./component/popup/popup";
import Home from "./page/Home";
import Header from "./component/header/Header";
import Footer from "./component/footer/Footer";
import { useEffect } from "react";

export default function Root(){

    const outlet = useOutlet();

    return<>
        <Popup/>
        
        <Header/>
        <main>
            {outlet || <Home/>}
        </main>
        <Footer/>
    </>
}