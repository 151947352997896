import { useParams } from "react-router-dom";
import CustomServices from "./../../services/services";
import { UserFetchUrl } from "./../../model/User.model";
import { useState } from "react";
import { Link } from "react-router-dom";
import style from "./stylepage/ConfirmMail.module.css";


function ConfirmMail() {

  let params = useParams();
  const [action, setAction] = useState<"yes"|"no"|"wait">("wait");
  const [btn, setBtn] = useState<boolean>(true);

  async function sendConfirmMail(e: React.MouseEvent<HTMLButtonElement, MouseEvent>){
      e.preventDefault() 
      setBtn(false);

      if(params.idmail){
        const id =  decodeURIComponent(params.idmail);
        let response = await CustomServices.sendFetch<boolean>(UserFetchUrl.ConfirmEmail(id), {
          method: "GET"
        })

        setAction(res => response.data ? "yes" : "no");
        response.error && alert(response.error.general);
      }else{
        setAction(res => "no");
        alert("email inesistente");
      }

      setBtn(true);
  }

  return <>
    <div className={style.box} >
      <h1 className={style.title}>Conferma la tua mail</h1>
      <p>Ci siamo quasi sei ad un click per accedere a fantastici contenuti e promozioni</p>
      <button className={style.btn} title="Conferma email" onClick={sendConfirmMail} disabled={!btn}>Conferma email</button>

      {action === "yes" && <p className={style.alert}>email confermata ritorna alla <Link to="../../../access">pagina di login</Link></p>}
      {action === "no" && <p className={style.alert}>link scaduto, vai alla <Link to="../../../access">pagina di login</Link> per poter riprovare</p>}
    </div>
  </>
    
}
export default ConfirmMail;