import { Link, useLoaderData } from "react-router-dom";
import CustomServices from "./../services/services";
import { GeventFetchUrl, GeventModel } from "./../model/Gevent.model";
import { useContext } from "react";
import { LoginContext } from "./../store/context";
import CodeGenerator from "./../component/staff/CodeGenerator";

function Gevent() {
  let [user , setUser] = useContext(LoginContext);  
  let gevent = useLoaderData() as GeventModel;

  const isStaff = user?.id &&  gevent.staff.includes(user.id);
  const isMaster = user?.id &&  gevent.master.includes(user.id);
  


  return <div className="max900 m_center">
    <h2 className="t_center">{gevent.name}</h2>
    <p className="t_center">{gevent.description}</p>
    {/*TODO: inserire html custom per evento 24/06/2024 */}
    {/*<div dangerouslySetInnerHTML={{__html:event.desHtml}} /> */}
    {(isStaff || isMaster) && <>
        <CodeGenerator idGevent={gevent._id} maxTry={5}/>
      </>
    }
    <div className="btn-box btn-box_center">
      <Link className="btn" to="play" title="gioca">Gioca</Link>

    </div>
  </div>
  }

export async function loaderGeventPage(name: string){

  let geventResp = await CustomServices.sendFetch<GeventModel>(GeventFetchUrl.getGevent(name.replaceAll("-"," ")), {
      method: "GET",
  })

  if(geventResp.error){
    alert(geventResp.error.general)
    window.location.href = "../"
  }

  localStorage.setItem(`${name}_src_play`, geventResp.data.src)
  return geventResp.data;
}

export default Gevent;