export default async function customFetchServics<res>(path: string , option: RequestInit){
    let cookies =  decodeURIComponent(document.cookie).split(';')
    let jwt = cookies.find((row) => row.startsWith("Jwt_User="))?.split("=")[1];

    option = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': process.env.REACT_APP_SERVER_URL,
            "authorization": jwt,
        },
        mode:"cors",
        credentials: "include",
        ...option
    }
 
    const basePath = process.env.REACT_APP_SERVER_URL
    const fetchReq = await fetch( basePath + "/api/" + path, {
        ...option
    });
    
    let response;
    switch(option.headers['Content-Type']){
        case "media-type":
            response = await fetchReq.blob() as res;
            break;
        default:
            response = await fetchReq.json();
    }
    //TODO: traduci messaggio
    if(fetchReq.status > 299 && fetchReq.status !== 401 && !response.error){
        response.error = {general: "Errore invio della richiesta"}
    }
    if(fetchReq.status === 401){
        if(response.error.reload){
            window.location.reload();
        }
        response.error = {general: response.error.general ?? "errore d'autenticazione"} 
    }
    return  response;
}