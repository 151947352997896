import { Schema } from "mongoose";

export type GeventModel ={
    _id: Schema.Types.ObjectId;
    name: string;
    description: string;
    desHtml: string;
    src: string;
    start: Date;
    end:Date;
    runGame: number;
    staff: string[];
    master: string[];
}





export class GeventFetchUrl{

    public static getGeventSrc(name:string){
        return `gevent/${name}/src`;
    }


    public static getGevent(name:string){
        return `gevent/${name}`;
    }

    public static getAllGevents(){
        return `gevent`;
    }
} 