import { Schema } from "mongoose";
import { FC, useContext, useState } from "react";
import CustomServices from "./../../services/services";
import GeneralStyle from "./../../App.module.css";
import { CodeFetchUrl, CreateCodeModel } from "./../../model/Code.model";
import style from "./Codegenerator.module.css"
import Form from "./../form/Form";
import Input from "./../form/Input";
import { I_PopupContext, PopUpContext } from "./../../store/context";

const CodeGenerator: FC<{idGevent: Schema.Types.ObjectId, maxTry: number}> = ({idGevent, maxTry}) => {

    const [code, setCode] = useState<string>("");
    const tentativi = useState<number>(3);
    const error = useState<{[name: string]: string|null}>({});

    const [popUp, setPopUp] = useContext(PopUpContext);

 
    async function GenerateCode(e: React.FormEvent<HTMLFormElement>){
        e.preventDefault();
        
        let body : CreateCodeModel = {
            idGevent,
            maxTry: Number(tentativi[0]),
        }
        
        const codeResponse = await CustomServices.sendFetch<string>(CodeFetchUrl.generateCodeEvent() , {
            method: "POST",
            body: JSON.stringify(body)
        });

        if(codeResponse.error){
            //TODO: popup error o errore alrt 24/06/2024
            if(codeResponse.error?.general) setPopUp({message:<p>{codeResponse.error.general}</p>});

            error[1](codeResponse.error);
        }else{
            setCode(codeResponse.data);
            // btn["disabled"] = false;
        }        
    }

    return (<div className={style["code-box"]}>
        <p>Genera il codice che consentirà al giocatore di accedere al gioco</p>
        {code && <p>{code}</p> }
        <Form onSubmit={GenerateCode} addClass={style["formCode"]}>
            <Input name="maxTry" label="tentativi" value={tentativi} error={error} min={1} max={15}/>
            <button type="submit" title="genera codice" 
                className={`${GeneralStyle["btn"]} ${GeneralStyle["btn_awaiter"]} btn`}>genera
            </button>
        </Form>
            
    </div>)
}

export default CodeGenerator;

