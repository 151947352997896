import { ObjectId } from "mongoose";

export type CreateCodeModel ={
    maxTry?: number
    idGevent: ObjectId,
    idCreator?: ObjectId,
}

export class GetCodeDto {
    code: string;
    try: number;
    maxTry: number;
    player: string|null;
}

export class AddCodeResDto {
    _id: string;
    code: string;
    try: number;
    maxTry: number;
    player: string|null;
}


export class CodeFetchUrl{

    public static generateCodeEvent() : string {
        return `code`;
    }

    public static assignCode() : string {
        return `code/assign`;
    }

    public static GetCode(codeId: string) : string {
        return `code/${codeId}`;
    }

    public static DeleteCode(codeId: string) : string {
        return `code/${codeId}`
    }
} 