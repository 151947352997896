import { useLoaderData } from "react-router-dom";
import CustomIFrame from "./../component/customIFrame/customIFrame";
import CustomServices from "./../services/services";
import { GeventFetchUrl } from "./../model/Gevent.model";


function Game() {
    let dataLoader = useLoaderData() as string;

    return (<>
      {dataLoader ? <CustomIFrame src={dataLoader}/> : <div>
            <p>evento finito o irraggiungibile</p>
        </div>}
    </>
    );
  }

export async function loaderGamePage(name: string){

  localStorage.setItem("api_server", process.env.REACT_APP_SERVER_URL);
  let srcGameString = localStorage.getItem(`${name}_src_play`);
  
  if(srcGameString === null){
      let respGame = await CustomServices.sendFetch<string>(GeventFetchUrl.getGeventSrc(name), {method: "GET"});
      
      srcGameString = respGame.data;
      localStorage.setItem(`${name}_src_play`, srcGameString);
  }

  return srcGameString;
}

  export default Game;