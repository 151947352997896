import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { LoginContext } from "./../../store/context";
import CustomServices from "./../../services/services";
import style from "./Header.module.css";
import { NavLink } from "react-router-dom";

const Header : FC = () => {

    const [user, setUser] = useContext(LoginContext);
    
    return <header className={style.header}>
        <Link  to="/" title="Home" className={style.title}>Adver<span>Play</span></Link>

        <nav className={style.nav}>
            <NavLink  to="/events" title="Home">Eventi</NavLink>
            {user 
                ? <NavLink to="Dashboard">Profilo</NavLink>
                : <NavLink to="./access">LogIn</NavLink> 
            }
        </nav>
    </header>
}

export default Header;