import { FC} from "react";
import style from "./Input.module.css";
import { InputProps } from "./../../model/Input.model";

const Input: FC<InputProps> = ({name, label, send , value , error, ...rest}) => {

 
    label= label || name 
    send = send || false
    
    let valueError = error[0]?.[name] || null;
    let [valueData, setValueData] = value;
    let isObjectData = typeof valueData === "object";
    let btn = send && <button>Invia</button>
    return (
        <div className={style["input-box"]}>
            <div className={'box-input'} key={"input"+name}>
                    <label htmlFor={name}>{label}</label>
                    <input name={name} id={name}  placeholder=' ' {...rest}
                        aria-invalid={valueError ? true : false} aria-describedby={valueError ? name+'-error-msg' : ""}
                        value={isObjectData ? (valueData[name] ?? '') : (valueData ?? '')}
                        onChange={e => {
                            if(error) error[1](prev => { return {...prev , [name]: null}});
                            if(isObjectData){
                                setValueData(prev => { return {...prev, [name]: e.target.value}})
                            }else{
                                setValueData(e.target.value);
                            }
                        }}
                    />
                    {btn}
            </div>
            <p className={style["input-alert"]}>{valueError && valueError}</p>
        </div>
    )
}

export default Input;

