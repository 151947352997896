export class UserFetchUrl{


    public static postRegistration(){
        return `user`;
    }

    public static PostChangeUsername(){
        return `user/changeusername`;
    }

    public static PostSendChangeEmail(){
        return `user/sendchangeemail`;
    }

    public static PostChangeEmail(){
        return `user/changeemail`;
    }

    public static PostChangeOldPassword(){
        return `user/changeoldpassword`;
    }

    public static ConfirmEmail(idMail:string){
        return `user/confirmemail/${idMail}`;
    }

    public static Changepassword(){
        return `user/changepass`;
    }

    public static SendRecoveryPassword(userEmail:string){
        return `user/sendrecoverypassword/${userEmail}`;
    }
} 

export class ChangeEmailDto{
    password: string;
    idMail: string;
}