import { useSearchParams } from "react-router-dom";
import CustomServices from "./../services/services";
import Input from "./../component/form/Input";
import { useState } from "react";
import { Link } from "react-router-dom";
import style from "./stylepage/Access.module.css"
import { OuthFetchUrl } from "./../model/Outh.model";
import { UserFetchUrl } from "./../model/User.model";
import Form from "./../component/form/Form";


function Access() {

    const data  = useState<{[name:string]: string|number}>({email: "" , pass: "", username:""})
    const error = useState<{[name:string]: string}>({})

    const [searchParams, setSearchParams] = useSearchParams();
    
    async function submitFormLogin() {
        data[0].email = data[0].email.toString().toLocaleLowerCase();
        let response = await CustomServices.sendFetch<boolean>(OuthFetchUrl.postOuthLocal(), {
          method:"post",
          body: JSON.stringify(data[0])
        })
 
        if(response.error){
          error[1](prev => {return {...prev, ...response.error}});
        }else{
          document.location.reload();
        }
    }

    async function submitFormRegistration(e: React.FormEvent<HTMLFormElement>) {
      e.preventDefault()
      data[0].tel = data[0].tel.toString(); 
      data[0].email = data[0].email.toString().toLocaleLowerCase(); 

      let response = await CustomServices.sendFetch<boolean>(UserFetchUrl.postRegistration(), {
        method:"post",
        body: JSON.stringify(data[0])
      })

      if(response.error){
        if(response.error.pass){
          response.error.pass = "Inserisci:\nminimo 8 caratteri \nalmeno una lettera maiuscola e minuscola \nalmeno un numero e \nalmeno un simbolo"
        }
        error[1](prev => {return {...prev, ...response.error}});
      }else{
        alert("Ti abbiamo mandato una mail per la conferma dell'account");
      }
  }

    async function recoveryPassword(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      e.preventDefault()
      
      let verification = CustomServices.validateData()
        .isEmail(data[0].email)

      if(verification.error){ 
        error[1](prev => {return {...prev, ...verification.error}})
        return;
      }

      let response = await CustomServices.sendFetch<boolean>(UserFetchUrl.SendRecoveryPassword(data[0].email as string), {
        method:"GET",
      })

      if(response.error){
        alert(response.error.general);
      }else{
        alert("Ti abbiamo inviato una mail per il cambio della password");
      }
    }


    return (<>
    {searchParams.get("reg") !== "1" ?
      <Form onSubmit={submitFormLogin} addClass={style.box}>
        <Input type="email" name="email" value={data} error={error}/>
        <Input type="password" name="pass" label="password" value={data} error={error}/>
        <p className="t_center">
          <button className={"btn_second"} onClick={recoveryPassword}>Password dimenticata</button>
        </p>
        
        <div className={style["btn-box"]}>
          <button className="btn" type="submit">Login</button>
          <Link title="registrazione" to={"?reg=1"}  onClick={e => {error[1]({})}}>Registrazione</Link>
        </div>

        <div className="form_alert-box">
          <p>{error[0]?.["general"]}</p>
        </div>
    </Form>
    :
    <Form onSubmit={submitFormRegistration} addClass={style.box}>
        <Input type="string" name="username" value={data} error={error}/>
        <Input type="email" name="email" value={data} error={error}/>
        <Input type="password" autoComplete="current-password" name="pass" label="password" value={data} error={error}/>
        <Input type="tel" name="tel" label="cellulare" value={data} error={error}/>

        <div className={style["btn-box"]}>
          <button className="btn" type="submit">Crea account</button>
          <Link title="registrazione" to={"?reg=0"}  onClick={e => {error[1]({})}}>Login</Link>
        </div>

        <div className="form_alert-box">
          <p>{error[0]?.["general"]}</p>
        </div>
    </Form>
    }
        
    </>
    );
  }

  export default Access;