import { FC, useContext, useState } from "react";
import { LoginContext, PopUpContext } from "./../../../store/context";
import style from "./Settings.module.css";
import Form from "./../../form/Form";
import Input from "./../../form/Input";
import CustomServices from "./../../../services/services";
import { UserFetchUrl } from "./../../../model/User.model";

const Settings : FC = () => {

    const [user, setUser] = useContext(LoginContext);
    const username = useState<string>(user.username);
    const email = useState<string>(user.email);
    const pass = useState<{password:string , newPassword}>({password:"", newPassword:""});
    const error = useState<{[name: string]: string}>({})
    
    const [popUp, setPopUp] = useContext(PopUpContext);

    async function changeUsername(e: React.FormEvent<HTMLFormElement>){
        e.preventDefault();
        error[1]({});
        
        let resp = await CustomServices.sendFetch<string>(UserFetchUrl.PostChangeUsername(), 
            {
                method: "POST",
                body: JSON.stringify({Username: username[0]})
            }
        );

        if(resp.error){
            error[1](resp.error)
        }else{
            document.location.reload()
        }

    }

    async function sendChangeEmail(e: React.FormEvent<HTMLFormElement>){
        e.preventDefault();
        error[1]({});
        
        let resp = await CustomServices.sendFetch<string>(UserFetchUrl.PostSendChangeEmail(), 
            {
                method: "POST",
                body: JSON.stringify({Email: email[0]})
            }
        );

        if(resp.error){
            error[1](resp.error)
        }else{
            console.log("qui")
            setPopUp({
                message: <p>è stata mandata una mail di conferma alla tua nuova email</p>,
                buttons: [<button type="button" onClick={e => document.location.reload()}>ok</button>]
            })
        }
    }

    async function changePassword(e: React.FormEvent<HTMLFormElement>){
        e.preventDefault();
        error[1]({});

        let resp = await CustomServices.sendFetch<string>(UserFetchUrl.PostChangeOldPassword(), 
            {
                method: "POST",
                body: JSON.stringify({
                    password: pass[0].password,
                    newPassword: pass[0].newPassword
                })
            }
        );

        if(resp.error){
            if(resp.error.newPassword){
                resp.error.newPassword = "Inserisci:\nminimo 8 caratteri \nalmeno una lettera maiuscola e minuscola \nalmeno un numero e \nalmeno un simbolo"
            }
            error[1](prev => {return {...prev, ...resp.error}});
            return;
        }

        if(resp.data){
            pass[1]({password:"", newPassword:""})
            setPopUp({message: <p>Password cambiata</p>})
        }
    }
    
    return <div className={style.box}>
        <Form onSubmit={changeUsername} addClass={style.form}>
            <Input type="text" name="Username" value={username} error={error} minLength={3} maxLength={15}/>    
            <button className="btn" type="submit" 
                disabled={username[0].length < 3 || username[0].length > 15 || username[0] === user.username}
            >Invia</button>    
        </Form>
        <Form onSubmit={sendChangeEmail} addClass={style.form}>
            <Input type="email" name="email" label="Email" value={email} error={error}/>    
            <button className="btn" type="submit" 
                disabled={email[0] === user.email}
            >Invia</button>    
        </Form>

        <Form onSubmit={changePassword} addClass={style.form}>
            <Input type="password" name="password" label="Password" value={pass} error={error} minLength={8}/>    
            <Input type="password" name="newPassword" label="Nuova password" value={pass} error={error}/>    
            <button className="btn" type="submit" disabled={
                pass[0].password.length < 8 ||
                pass[0].newPassword.length < 8 ||
                pass[0].password === pass[0].newPassword
            }>Invia</button>    
        </Form>
    </div>
}

export default Settings;