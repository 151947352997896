import { FC, useState} from "react";
import style from "./Form.module.css";
import { FormProps} from "./../../model/Input.model";


const Form: FC<FormProps> = ({title, addClass, children, onSubmit: onCustomSubmit}) => {

    async function submit(e : React.FormEvent<HTMLFormElement> ){
        e.preventDefault();

        let form = e.target  as HTMLFormElement
        let btns = form.getElementsByTagName("button") as HTMLCollectionOf<HTMLButtonElement>;
        let btnSubmit : HTMLButtonElement = null;
        for(let i=0 ; btns.length > i; i++){
            if(btns[i].getAttribute("type") === "submit"){
                btnSubmit = btns[i];
            }
        }
        
        if(btnSubmit !== null ){ 
            btnSubmit.disabled = true;
        }

        try{
            await onCustomSubmit(e);
        }catch(ex){
            console.error(ex);
        }

        if(btnSubmit !== null ){
            btnSubmit.disabled = false;
        }
    } 


    return (
        <form className={style.form +" "+( addClass || "")} onSubmit={submit}>
            {children}
        </form>
    )
}

export default Form;